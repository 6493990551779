import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import moment from 'moment'
import './with-lunr.css'

const SearchResults = ({ results, query }) => (
    
  <IndexWrapper>
    <h2
        className="search-results-count"
        id="search-results-count"
        aria-live="assertive"
    >Found {results.length} articles on "{query}"</h2>
    {results.map(
      ({ id, title, slug, date, category }) => (
        <PostWrapper key={id}>
          <Link to={slug}>
            
            <ArticleWrapper>
              <Content>
                <Category>found in {category}, from {moment(date).fromNow(true)} ago</Category>
                <Title>{title}</Title> 
              </Content>
            </ArticleWrapper>
          </Link>
        </PostWrapper>
      )
    )}
  </IndexWrapper>

);

export default SearchResults

const IndexWrapper = styled.main`
  
  margin-top: 10px;
  
  a {
    text-decoration: none;
  }

  @media only screen and (max-width: 800px) {
    padding: 15px;
  }
`

const PostWrapper = styled.div`
  margin: 1rem auto;
`

const ArticleWrapper = styled.div`
  display: flex;
`

const Content = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Category = styled.div`
  text-transform: uppercase;
  font-family: 'Josefin Sans', 'sans-serif';
  color: black;
  font-size: 1rem;
`

const Title = styled.h1`
  font-family: 'Oswald', 'sans-serif';
  font-weight: 700;
  color: black;
  font-size: 1.2rem;
`

