import React, { useState, useEffect } from 'react'
import { SinglePostLayout } from '../components/Layouts/SinglePostLayout'
import { SearchResults } from '../components/Search'
//URLSEARCHPARAMS IS NOT SUPPORTED IN INTERNET EXPLORER. FORTUNATELY, THERE IS THIS POLYFILL
import 'url-search-params-polyfill';

const Search = ({ location }) => {
    const [results, setResults] = useState([])
    const searchQuery = new URLSearchParams(location.search).get('keywords') || '';

    useEffect(() => {
        if (window.__LUNR__) {
            window.__LUNR__.__loaded.then(lunr => {
                const refs = lunr.en.index.search(`*${searchQuery}*`);
                const posts = refs.map(({ ref }) => lunr.en.store[ref]);
                setResults(posts) 
            });
        }
    }, [location.search, searchQuery])
    
    return (
        <SinglePostLayout>
            {/* <SearchForm query={searchQuery} /> */}
            <SearchResults 
                results={results}
                query={searchQuery} 
            />
        </SinglePostLayout>
    )
}

export default Search


